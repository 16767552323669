import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql, Link} from 'gatsby'
import Container from '../styles/container'
import Img from 'gatsby-image'

const HeaderComponent = styled.header`
  position: relative;
  color: #000;
  z-index: 100;
  top: 0;
  left: 0;
  z-index: 80;
  margin-top: ${props => props.theme.space[6]};
  margin-bottom: ${props => props.theme.space[8]};

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-bottom: ${props => props.theme.space[12]};
  }

  a {
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
`

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.theme.space[10]};
  width: 100%;

  .gatsby-image-wrapper:first-child {
    margin-bottom: ${props => props.theme.space[3]};
  }

  .logo {
    .gatsby-image-wrapper {
      width: 200px;

      @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        width: 300px;
      }
    }
  }
}`

const Links = styled.nav`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.space[6]};

  a {
    text-decoration: none;
    font-family: 'Ayustina', 'Dancing Script';
    padding: 0 ${props => props.theme.space[5]};

    @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
      font-size: ${props => props.theme.fontSizes[3]};
      padding: 0 ${props => props.theme.space[12]};
    }

    &:hover {
      color: ${props => props.theme.colors.grey};
    }

    &.active {
      font-weight: 600;
    }
  }
`

export default function Header ({path}) {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          bird: file(relativePath: {eq: "schwalbe.png"}) {
            id
            childImageSharp {
              id
              fixed(width: 70, height: 60) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          logo: file(relativePath: {eq: "schrift_logo.png"}) {
            id
            childImageSharp {
              id
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }`}
      render={data => (
        <HeaderComponent>
          <Container>
            <Logo>
              <Link to='/'>{data.bird.childImageSharp ? <Img fixed={data.bird.childImageSharp.fixed} alt='Petite Hirondelle' /> : ''} </Link>
              <Link className='logo' to='/'> {data.logo.childImageSharp ? <Img fluid={data.logo.childImageSharp.fluid} alt='Petite Hirondelle' /> : ''}</Link>
            </Logo>

            <Links>
              <span className='navigation'>
                <Link activeClassName='active' to='/ueber-mich'>ueber mich</Link>
                <Link activeClassName='active' to='/portfolio'>Portfolio</Link>
                <Link activeClassName='active' to='/contact'>Kontakt</Link>
              </span>
            </Links>
          </Container>
        </HeaderComponent>
      )}
    />
  )
}
