import styled from 'styled-components'

const Container = styled.main`
  max-width: 1000px;
  margin: 0 auto;
  width: 90vw;
  /* margin-bottom: ${props => props.theme.space[12]}; */

  p, address, a {
    font-size: ${props => props.theme.fontSizesNamed.small};
    line-height: ${props => props.theme.space[5]};

    @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
      font-size: ${props => props.theme.fontSizes[2]};
      line-height: ${props => props.theme.space[6]};
    }
  }
  
  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    width: calc(${props => props.theme.breakpoints[1]} - 50px);
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    width: calc(${props => props.theme.breakpoints[2]} - 100px);
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints[3]}) {
    width: calc(${props => props.theme.breakpoints[3]} - 100px);
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints[4]}) {
    width: calc(${props => props.theme.breakpoints[4]} - 100px);
  }
`

export default Container
