import React  from "react"
import styled from 'styled-components'
import {FaInstagram} from "react-icons/fa";
import Container from '../../styles/container'
import FooterContact from './footer_contact'
import {Link} from 'gatsby'

const FooterContainer = styled.footer`
  max-width: 100vw;
  min-height: 30vh;
  color: ${props => props.theme.colors.greytwo};
  margin-top: ${props => props.theme.space[12]};

  hr {
    background: linear-gradient(to right, transparent 0%, rgba(204, 204, 204, 0.9) 49%, #fff 100%);
    border: none;
    height: 0.7px;
    width: 100vw;
  }

  .wrapper {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h3 {
      margin-bottom: 10px;
      font-size: 1rem;
    }

    a {
      font-size: 0.8rem;
      color: ${props => props.theme.colors.greytwo};
      text-decoration: underline;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
      flex-direction: row;
    }

    & > div {
      margin-bottom: 2vh;
      max-width: 400px;
      
      p {
        font-size: 0.8rem;
        line-height: 1.2rem;
      }
    }

    .about {
      width: 100%;

      @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        width: 25%;
      }

      h3 {
        font-size: 2rem;
        color: hsl(0, 0%, 58%);
      }
    }


    .author {
      width: 100%;

      @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        width: 35%;
      }

      address {
        font-style: normal;
        font-size: 0.8rem;
        line-height: 1.2rem;
      }
    }

    .information {
      width: 100%;

      @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        width: 25%;
      }

      .icons {
        display: flex;

        a > svg {
          padding-right: 15px;
          font-size: 2rem;
          height: 30px;
        }
      }
    }
  }
`;


class Footer extends React.Component {
  render () {
    return (
      <FooterContainer>
        <hr />
        <Container className="wrapper">
          <div className="about">
            <h3>petite hirondelle</h3>
          </div>

          <div className="author">
            <FooterContact />
          </div>
          <div className="information">
            <p>© {new Date().getFullYear()} Alle Rechte vorbehalten </p>
            <Link to="/impressum">Impressum</Link>
            <p>Konzeption und Programmierung: <a href="https://christianburkhart.de">Christian Burkhart</a></p>
          </div>
        </Container>
      </FooterContainer>
    )
  }
}

export default Footer