import { createGlobalStyle } from 'styled-components'
import theme from '../lib/theme'

const GlobalStyles = createGlobalStyle`
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    color: white;
    background-color: #D6B47A;
  }

  html, body {
    overflow-x:hidden 
  }

  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    
    h1, h2, h3, h4, h5, h6 {
      font-weight: 300;
      font-family: "Ayustina", "Dancing Script";
      /* &:hover {
        color: hsl(0, 0%, 90%);
      } */
    }
    
    h1 {
      font-size: ${theme.fontSizes[5]};
    }
    h2 {
      font-size: ${theme.fontSizes[4]};
      margin-bottom: 0;
    }
    h3 {
      font-size: ${theme.fontSizes[3]};
    }
    h4 {
      font-size: ${theme.fontSizes[2]};
    }
    h5 {
      font-size: ${theme.fontSizes[1]};
    }
    h6 {
      font-size: ${theme.fontSizes[0]};
    }
    
    @media (max-width: 600px) {
      font-size: 16px;
      
      h1 {
        font-size: ${theme.fontSizes[4]};
      }
      h2 {
        font-size: ${theme.fontSizes[3]};
      }
      h3 {
        font-size: ${theme.fontSizes[2]};
      }
      h4 {
        font-size: ${theme.fontSizes[1]};
      }
      h5 {
        font-size: ${theme.fontSizes[0]};
      }
      h6 {
        font-size: ${theme.fontSizes[0]};
      }
    }
  }

  body {
    border: 0;
    margin: 0;
    padding: 0;
    color: black;
    font-family: 'Quicksand', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background: #fdfcf8;
    font-size: 18px;
    overflow-x: hidden;
  }

  a {
    font-size: ${theme.fontSizesNamed.small};
    transition: all 0.3s ease-in-out;
    box-shadow: none;
    color: black;
    &:hover,
    &:focus {
      color: ${theme.colors.primary};
    }
  }

  p, address {
    font-size: ${theme.fontSizesNamed.verysmall};
    font-weight: 300;
    line-height: ${theme.space[4]};
    margin-top: 0;
  }
`

export default GlobalStyles;