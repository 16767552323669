import React from "react"
import theme from '../lib/theme'
import GlobalStyles from '../styles/global_styles'
import { ThemeProvider } from 'styled-components'
import { Helmet } from "react-helmet"
import Header from '../components/header'
import Footer from './footer/footer'
import { ParallaxProvider } from 'react-scroll-parallax';

const Layout = ({ path, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script&family=Quicksand:wght@300;400&display=swap" rel="stylesheet"></link>
      </Helmet>

      <GlobalStyles />

      <Header path={path} />

      <ParallaxProvider>
        {children}
      </ParallaxProvider>
      
      <Footer />
    </ThemeProvider>
  )
}


export default Layout